// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-blog-category-strapi-category-slug-tsx": () => import("./../../../src/pages/blog/category/{StrapiCategory.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-category-strapi-category-slug-tsx" */),
  "component---src-pages-blog-strapi-article-slug-tsx": () => import("./../../../src/pages/blog/{StrapiArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-article-slug-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blog-list-template.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */)
}

